import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.css';
import './index.css';
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import logo from './smp_header_med.png';
import dataJSON from './data/data.json';

const App = () => {
    const [stackedData, setData] = useState(dataJSON.group[0].data);
    const [stackedTitle, setTitle] = useState(dataJSON.group[0].title);
    useEffect(() => {
        function setData() {
            stackedData = dataJSON.group[0].data;
        };
        function setTitle() {
            stackedTitle = dataJSON.group[0].title;
        };
    }, []); 

    const stackedOptions = {
        tooltips: {
            mode: 'index',
            intersect: false
        },
        responsive: true,
        scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                stacked: true
            }]
        }
    };

    return (
        <div className="grid-container">
            <div className="grid1">
                    <img src={logo} alt="Shop My Politics"></img>
            </div>
            <div className="grid2">
                <Button label="Department Stores"  onClick={() => {setData(dataJSON.group[0].data); setTitle(dataJSON.group[0].title)}} className={`p-button-rounded button p-button-dept-stores`} icon="pi pi-chevron-right" iconPos="right" /><br /> 
                <Button label="Online Stores"  onClick={() => {setData(dataJSON.group[1].data); setTitle(dataJSON.group[1].title)}} className={`p-button-rounded button p-button-danger p-button-online-stores`} icon="pi pi-chevron-right" iconPos="right" /><br /> 
                <Button label="Restaurants" onClick={() => {setData(dataJSON.group[2].data); setTitle(dataJSON.group[2].title)}} className={`p-button-rounded button p-button-restaurants`} icon="pi pi-chevron-right" iconPos="right" /><br /> 
                <Button label="Health &amp; Household"  onClick={() => {setData(dataJSON.group[3].data); setTitle(dataJSON.group[3].title)}} className={`p-button-rounded button p-button-danger p-button-household`} icon="pi pi-chevron-right" iconPos="right" /><br /> 
                <Button label="Entertainment"  onClick={() => {setData(dataJSON.group[4].data); setTitle(dataJSON.group[4].title)}} className={`p-button-rounded button p-button-entertainment`} icon="pi pi-chevron-right" iconPos="right" /><br /> 
                <Button label="Technology"  onClick={() => {setData(dataJSON.group[5].data); setTitle(dataJSON.group[5].title)}} className={`p-button-rounded button p-button-danger p-button-technology`} icon="pi pi-chevron-right" iconPos="right" /><br /> 
                <Button label="Grocery"  onClick={() => {setData(dataJSON.group[6].data); setTitle(dataJSON.group[6].title)}} className={`p-button-rounded button p-button-grocery`} icon="pi pi-chevron-right" iconPos="right" /><br /> 
                <Button label="Lodging"  onClick={() => {setData(dataJSON.group[7].data); setTitle(dataJSON.group[7].title)}} className={`p-button-rounded button p-button-danger p-button-lodging`} icon="pi pi-chevron-right" iconPos="right" /><br /> 
                <Button label="Repair"  onClick={() => {setData(dataJSON.group[8].data); setTitle(dataJSON.group[8].title)}} className={`p-button-rounded button p-button-repair`} icon="pi pi-chevron-right" iconPos="right" /><br /> 
                <Button label="Transportation"  onClick={() => {setData(dataJSON.group[9].data); setTitle(dataJSON.group[9].title)}} className={`p-button-rounded button p-button-danger p-button-transportation`} icon="pi pi-chevron-right" iconPos="right" />
                <Card style={{ marginTop: '2em', marginBottom: '2em' }} >
                    Future AD Content
                </Card>
                <Card style={{ marginTop: '2em', marginBottom: '2em' }} >
                    More Future AD Content
                </Card>
                <Card style={{ marginTop: '2em', marginBottom: '2em' }} >
                    Extra Future AD Content
                </Card>
            </div>
            <div className="grid3">
                <h2 className="headerCenter">{stackedTitle}</h2>
                <Chart type="horizontalBar" data={stackedData} options={stackedOptions} />
            </div>
        </div>
    )
}

export default App;
